import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const Nav = () => {
  const [openBurger, setOpenBurger] = useState(false);
  const body = document.querySelector("body");
  const navvy = document.querySelector("nav");

  const BurgerClick = () => {
    openBurger ? setOpenBurger(false) : setOpenBurger(true);
    // if (openBurger) {
    //   body.style.position = "static";
    // } else {
    //   body.style.position = "fixed";
    // }
  };

  return (
    <StyledNav className={openBurger ? "burger-active" : "top-nav"}>
      <LogoAnimation>
        <svg
          width={190}
          height={30}
          viewBox="0 0 373 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="seanmc"
        >
          <path
            d="M25.7461 71.96C21.1381 71.96 17.0421 71.192 13.4581 69.656C9.93812 68.12 6.51412 65.72 3.18612 62.456C2.41812 61.816 2.03412 61.048 2.03412 60.152C2.03412 59.384 2.32212 58.68 2.89812 58.04C3.53812 57.4 4.24212 57.08 5.01012 57.08C5.77812 57.08 6.48212 57.4 7.12212 58.04C12.1141 63.544 18.3541 66.296 25.8421 66.296C30.4501 66.296 34.2581 65.208 37.2661 63.032C40.3381 60.856 41.8741 58.008 41.8741 54.488C41.8741 51.736 41.1061 49.464 39.5701 47.672C38.0981 45.816 36.1461 44.312 33.7141 43.16C31.2821 42.008 28.1781 40.824 24.4021 39.608C20.3061 38.392 16.8501 37.048 14.0341 35.576C11.2821 34.104 9.04212 32.152 7.31412 29.72C5.58612 27.224 4.72212 24.088 4.72212 20.312C4.72212 16.92 5.61812 13.912 7.41012 11.288C9.26612 8.66399 11.8261 6.616 15.0901 5.144C18.4181 3.608 22.2261 2.84 26.5141 2.84C30.4181 2.84 34.0661 3.512 37.4581 4.856C40.9141 6.136 43.6661 7.992 45.7141 10.424C46.6741 11.512 47.1541 12.44 47.1541 13.208C47.1541 13.912 46.8341 14.584 46.1941 15.224C45.5541 15.8 44.8501 16.088 44.0821 16.088C43.4421 16.088 42.8981 15.864 42.4501 15.416C40.7221 13.368 38.4181 11.704 35.5381 10.424C32.6581 9.144 29.6501 8.504 26.5141 8.504C21.8421 8.504 18.0341 9.56 15.0901 11.672C12.1461 13.72 10.6741 16.536 10.6741 20.12C10.6741 23.832 12.0821 26.68 14.8981 28.664C17.7141 30.648 21.7461 32.472 26.9941 34.136C31.5381 35.48 35.2501 36.856 38.1301 38.264C41.0101 39.672 43.3461 41.688 45.1381 44.312C46.9941 46.872 47.9221 50.264 47.9221 54.488C47.9221 57.752 46.9621 60.728 45.0421 63.416C43.1861 66.04 40.5621 68.12 37.1701 69.656C33.8421 71.192 30.0341 71.96 25.7461 71.96Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
          <path
            d="M101.54 43.64C101.54 44.408 101.252 45.08 100.676 45.656C100.1 46.168 99.4284 46.424 98.6604 46.424H62.3724C62.3724 50.264 63.1404 53.72 64.6764 56.792C66.2764 59.8 68.5164 62.2 71.3964 63.992C74.3404 65.72 77.7964 66.584 81.7644 66.584C84.8364 66.584 87.5884 65.944 90.0204 64.664C92.4524 63.384 94.4044 61.752 95.8764 59.768C96.5804 59.192 97.2524 58.904 97.8924 58.904C98.5324 58.904 99.1084 59.16 99.6204 59.672C100.132 60.184 100.388 60.76 100.388 61.4C100.388 62.104 100.068 62.776 99.4284 63.416C97.3804 65.912 94.7884 67.96 91.6524 69.56C88.5804 71.16 85.3164 71.96 81.8604 71.96C76.9324 71.96 72.5804 70.904 68.8044 68.792C65.0284 66.68 62.0844 63.736 59.9724 59.96C57.8604 56.12 56.8044 51.704 56.8044 46.712C56.8044 41.464 57.8284 36.888 59.8764 32.984C61.9884 29.016 64.7724 26.008 68.2284 23.96C71.6844 21.848 75.3644 20.792 79.2684 20.792C83.1084 20.792 86.6924 21.688 90.0204 23.48C93.4124 25.272 96.1644 27.896 98.2764 31.352C100.388 34.808 101.476 38.904 101.54 43.64ZM79.2684 26.36C75.5564 26.36 72.1004 27.64 68.9004 30.2C65.7644 32.76 63.7484 36.44 62.8524 41.24H95.5884V40.472C95.2684 37.592 94.3084 35.096 92.7084 32.984C91.1084 30.808 89.0924 29.176 86.6604 28.088C84.2924 26.936 81.8284 26.36 79.2684 26.36Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
          <path
            d="M153.627 21.944C154.459 21.944 155.131 22.232 155.643 22.808C156.219 23.384 156.507 24.088 156.507 24.92V68.12C156.507 68.952 156.219 69.656 155.643 70.232C155.131 70.744 154.459 71 153.627 71C152.795 71 152.091 70.744 151.515 70.232C151.003 69.656 150.747 68.952 150.747 68.12V61.688C149.147 64.568 146.747 67 143.547 68.984C140.347 70.968 136.859 71.96 133.083 71.96C128.795 71.96 124.923 70.84 121.467 68.6C118.075 66.36 115.387 63.288 113.403 59.384C111.483 55.48 110.523 51.096 110.523 46.232C110.523 41.368 111.515 37.016 113.499 33.176C115.483 29.272 118.171 26.232 121.563 24.056C125.019 21.88 128.827 20.792 132.987 20.792C136.827 20.792 140.347 21.72 143.547 23.576C146.747 25.432 149.147 27.864 150.747 30.872V24.92C150.747 24.088 151.003 23.384 151.515 22.808C152.091 22.232 152.795 21.944 153.627 21.944ZM133.659 66.584C136.987 66.584 139.963 65.72 142.587 63.992C145.275 62.2 147.355 59.768 148.827 56.696C150.363 53.56 151.131 50.072 151.131 46.232C151.131 42.52 150.363 39.128 148.827 36.056C147.355 32.984 145.275 30.584 142.587 28.856C139.963 27.064 136.987 26.168 133.659 26.168C130.331 26.168 127.323 27.032 124.635 28.76C122.011 30.488 119.931 32.888 118.395 35.96C116.923 38.968 116.187 42.392 116.187 46.232C116.187 50.072 116.923 53.56 118.395 56.696C119.867 59.768 121.915 62.2 124.539 63.992C127.227 65.72 130.267 66.584 133.659 66.584Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
          <path
            d="M194.673 20.792C200.625 20.792 204.977 22.552 207.729 26.072C210.545 29.528 211.953 34.136 211.953 39.896V68.12C211.953 68.952 211.665 69.656 211.089 70.232C210.513 70.744 209.841 71 209.073 71C208.241 71 207.537 70.744 206.961 70.232C206.449 69.656 206.193 68.952 206.193 68.12V40.184C206.193 36.024 205.137 32.664 203.025 30.104C200.977 27.48 197.777 26.168 193.425 26.168C190.673 26.168 188.049 26.808 185.553 28.088C183.121 29.368 181.137 31.096 179.601 33.272C178.065 35.384 177.297 37.688 177.297 40.184V68.12C177.297 68.952 177.009 69.656 176.433 70.232C175.921 70.744 175.249 71 174.417 71C173.585 71 172.881 70.744 172.305 70.232C171.793 69.656 171.537 68.952 171.537 68.12V25.112C171.537 24.28 171.793 23.608 172.305 23.096C172.881 22.52 173.585 22.232 174.417 22.232C175.249 22.232 175.921 22.52 176.433 23.096C177.009 23.608 177.297 24.28 177.297 25.112V30.392C179.025 27.64 181.489 25.368 184.689 23.576C187.953 21.72 191.281 20.792 194.673 20.792Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
          <path
            d="M313.287 3.8C314.055 3.8 314.695 4.088 315.207 4.664C315.783 5.176 316.071 5.848 316.071 6.68V68.12C316.071 68.952 315.783 69.656 315.207 70.232C314.631 70.744 313.927 71 313.095 71C312.199 71 311.463 70.744 310.887 70.232C310.311 69.656 310.023 68.952 310.023 68.12V15.32L287.463 49.496C286.823 50.328 286.087 50.712 285.255 50.648H285.063C284.295 50.648 283.591 50.264 282.951 49.496L260.391 15.128V68.12C260.391 68.952 260.103 69.656 259.527 70.232C259.015 70.744 258.343 71 257.511 71C256.679 71 256.007 70.744 255.495 70.232C254.983 69.656 254.727 68.952 254.727 68.12V6.68C254.727 5.912 254.983 5.24 255.495 4.664C256.071 4.088 256.775 3.8 257.607 3.8C258.055 3.8 258.535 3.928 259.047 4.184C259.559 4.376 259.943 4.664 260.199 5.048L285.351 42.776L310.695 5.048C311.399 4.088 312.263 3.672 313.287 3.8Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
          <path
            d="M353.909 20.792C356.917 20.792 359.701 21.144 362.261 21.848C364.885 22.488 366.965 23.384 368.501 24.536C370.101 25.688 370.901 26.936 370.901 28.28C370.901 28.984 370.645 29.624 370.133 30.2C369.685 30.776 369.109 31.064 368.405 31.064C367.765 31.064 367.221 30.936 366.773 30.68C366.389 30.424 365.941 30.04 365.429 29.528C364.597 28.696 363.797 28.088 363.029 27.704C362.069 27.256 360.789 26.904 359.189 26.648C357.653 26.328 355.989 26.168 354.197 26.168C350.677 26.168 347.445 27.064 344.501 28.856C341.621 30.648 339.349 33.08 337.685 36.152C336.021 39.224 335.189 42.616 335.189 46.328C335.189 50.168 335.989 53.624 337.589 56.696C339.189 59.768 341.397 62.2 344.213 63.992C347.093 65.72 350.357 66.584 354.005 66.584C356.309 66.584 358.133 66.392 359.477 66.008C360.885 65.624 362.261 65.08 363.605 64.376C364.053 64.12 364.821 63.544 365.909 62.648C366.421 62.2 366.869 61.88 367.253 61.688C367.637 61.432 368.053 61.304 368.501 61.304C369.269 61.304 369.877 61.56 370.325 62.072C370.773 62.52 370.997 63.128 370.997 63.896C370.997 64.984 370.229 66.168 368.693 67.448C367.157 68.664 365.045 69.72 362.357 70.616C359.669 71.512 356.693 71.96 353.429 71.96C348.757 71.96 344.597 70.872 340.949 68.696C337.301 66.456 334.453 63.384 332.405 59.48C330.421 55.576 329.429 51.192 329.429 46.328C329.429 41.656 330.453 37.4 332.501 33.56C334.549 29.656 337.429 26.552 341.141 24.248C344.853 21.944 349.109 20.792 353.909 20.792Z"
            stroke="var(--green)"
            strokeWidth={3}
            mask="url(#path-1-outside-1_1_4)"
          />
        </svg>
      </LogoAnimation>
      <ul>
        <li onClick={BurgerClick}>
          <Link className="link" to="/">
            Tours
          </Link>
        </li>
        <li onClick={BurgerClick}>
          <Link className="link" to="/Dublin">
            Dublin
          </Link>
        </li>
        <li onClick={BurgerClick}>
          <Link className="link" to="/music">
            Music
          </Link>
        </li>
        <li onClick={BurgerClick}>
          <Link className="link" to="/food">
            Food
          </Link>
        </li>
        <li onClick={BurgerClick}>
          <Link className="link" to="/reviews">
            Reviews
          </Link>
        </li>
      </ul>
      <div
        id="burger"
        onClick={BurgerClick}
        className={openBurger ? "burger-active" : "top-nav"}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
  height: 10vh;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 21;

  #burger{
      cursor: pointer;
      margin-right: 1rem;
      div {
      width: 25px;
      height: 3px;
      border-radius: 50px;
      background-color: var(--light-grey);
      margin: 5px;
      transition: all 0.3s ease;
      }
      
    @media (min-width: 768px) {
      display: none;
    }
  }

  ul {
    position: absolute;
    right: 0px;
    /* height: 90vh; */
    top: 10vh;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
    transition: transform 0.5s ease-in;
    @media (min-width: 768px) {
      position: relative;
      top: auto;
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0 2rem;
      margin: 0;
    }
  a {
    color: var(--light-grey);
    text-decoration: none;
    font-weight: bold;
    :hover {
      color: var(--green);
    }
  }

  #logo {
    font-size: 1.5rem;
    font-family: "Lobster", cursive;
    font-weight: lighter;
    margin: 0.5rem 0;
    @media (min-width: 992px) {
      margin: 0 1rem;
      svg {
        width: 182px;
        height: 39px;
      }
    }
    @media (min-width: 1200px) {
      svg {
        width: 298px;
        height: 59px;
      }
    }
  }
  li {
    position: relative;
    padding: 1rem 1rem;
    /* opacity: 0; */
    /* @media (min-width: 768px) {
    opacity: 1;
  } */
  }
  @media (min-width: 992px) {
    padding: 0;
  }
  @media (min-width: 1200px) {
    li {
      padding-left: 5rem;
    }
  }
`;

const LogoAnimation = styled.div`
  margin: 0.5rem;
  @media (min-width: 992px) {
    margin: 1rem;
    #seanmc {
      width: 250px;
      height: 55px;
    }
  }
  @media (min-width: 1200px) {
    margin: 1rem 2rem;
  }
  #seanmc path:nth-child(1) {
    stroke-dasharray: 322px;
    stroke-dashoffset: 322;
    animation: line-animation 2s ease forwards 1.2s;
  }
  #seanmc path:nth-child(2) {
    stroke-dasharray: 312;
    stroke-dashoffset: 312;
    animation: line-animation 2s ease forwards 1.3s;
  }
  #seanmc path:nth-child(3) {
    stroke-dasharray: 312;
    stroke-dashoffset: 312;
    animation: line-animation 2s ease forwards 1.6s;
  }
  #seanmc path:nth-child(4) {
    stroke-dasharray: 255;
    stroke-dashoffset: 255;
    animation: line-animation 2s ease forwards 1.9s;
  }
  #seanmc path:nth-child(5) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    animation: line-animation 2s ease forwards 1.6s;
  }
  #seanmc path:nth-child(6) {
    stroke-dasharray: 221;
    stroke-dashoffset: 221;
    animation: line-animation 2s ease forwards 2.1s;
  }

  @keyframes line-animation {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes flicker {
    10%,
    30%,
    60%,
    75% {
      opacity: 1;
    }
    20%,
    40%,
    70% {
      opacity: 0.45;
    }
  }
`;

export default Nav;
